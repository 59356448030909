var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"px-16 mt-8"},[_c('loading-flux',{attrs:{"value":_vm.loading}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('section',{staticClass:"d-flex flex-column flex-sm-row justify-content-between align-items-center"},[_c('h2',{staticClass:"my-3"},[(_vm.isPresale)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":"lg"},on:{"click":function($event){return _vm.$router.push('/?presale_tab=true')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-arrow-left ")])]}}],null,false,4284707270)},[_c('span',[_vm._v("Volver")])]):_vm._e(),_vm._v(" Documentos de "+_vm._s(_vm.getTitleFromIsProjectOrPresale)+" "+_vm._s(_vm.getCodeFromIsProjectOrPresale)+" ")],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('v-btn',{staticClass:"text-white",attrs:{"color":"primary","dark":false},on:{"click":function($event){return _vm.openModalFilterDocuments()}}},[_c('i',{staticClass:"mdi mdi-filter me-1"}),_vm._v(" Filtros ")]),_c('v-btn',{staticClass:"text-white mx-4",attrs:{"disabled":_vm.loadingDocuments || !_vm.documents.length,"color":"primary","dark":false},on:{"click":function($event){return _vm.openModalUploadDocument(null)}}},[_c('i',{staticClass:"mdi mdi-upload me-1"}),_vm._v(" Cargar Nuevo Documento ")])],1)])]),_c('v-col',{staticClass:"px-12",attrs:{"cols":"12","md":"11","sm":"12"}},[(_vm.loadingDocuments)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('p',{staticClass:"text-center"},[_vm._v("Cargando Documentos... Espere por favor")]),_c('v-progress-circular',{staticClass:"mx-auto",attrs:{"size":70,"width":7,"color":"primary","indeterminate":""}})],1):(_vm.documents.length)?_c('v-treeview',{attrs:{"shaped":"","open":_vm.foldersOpened,"items":_vm.documents,"item-key":"id","open-on-click":"","hoverable":false},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(item.children)?_c('div',{staticClass:"d-flex align-items-center"},[_c('v-icon',{staticClass:"mr-1",on:{"click":function($event){open = !open}}},[_vm._v(" "+_vm._s(open ? "mdi-folder-open" : "mdi-folder")+" ")]),(item.children)?_c('h5',{staticClass:"mt-3 mx-1"},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()],1):_c('v-simple-table',{staticClass:"mt-2",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left px-2"},[_c('h6',{staticClass:"font-weight-bold"},[_vm._v("Acción")])]),_c('th',{staticClass:"text-left px-1"},[_c('h6',{staticClass:"font-weight-bold"},[_vm._v("Documento")])]),_c('th',{staticClass:"text-center px-1"},[_c('h6',{staticClass:"font-weight-bold"},[_vm._v("Área Responsable")])]),_c('th',{staticClass:"text-center px-1"},[_c('h6',{staticClass:"font-weight-bold"},[_vm._v("Nombre de Documento")])]),_c('th',{staticClass:"text-center px-1"},[_c('h6',{staticClass:"font-weight-bold"},[_vm._v("Comentario")])]),_c('th',{staticClass:"text-center px-1"},[_c('h6',{staticClass:"font-weight-bold"},[_vm._v("Fecha Subida")])]),_c('th',{staticClass:"text-center px-1"},[_c('h6',{staticClass:"font-weight-bold"},[_vm._v("Último Cambio")])])])]),_c('tbody',_vm._l((item.documents),function(document){return _c('tr',{key:document.id},[_c('td',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"float-left",attrs:{"color":"red"},on:{"click":function($event){return _vm.sendDeleteDocument(document)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar Documento")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"float-left mx-2",attrs:{"color":"warning"},on:{"click":function($event){return _vm.openModalUploadDocument(document)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Editar Documento")])])],1),_c('td',[_c('div',{staticClass:"text-left"},[_c('v-btn',{attrs:{"icon":"","href":document.url,"target":"_blank"}},[_c('v-icon',{staticClass:"mx-3"},[_vm._v(" "+_vm._s(_vm.getIconMaterialFromExtension(document.extension))+" ")])],1)],1)]),_c('td',[_c('div',{staticClass:"text-center"},[_c('v-chip',{staticClass:"text-white",attrs:{"label":"","small":"","ripple":"","color":"teal accent-4"}},[_vm._v(" "+_vm._s(document.document_type.assigned_area.name)+" ")])],1)]),_c('td',[_c('p',{staticClass:"my-0 mx-2"},[_vm._v(" "+_vm._s(document.document_type.name)+" ")])]),_c('td',[_c('p',{staticClass:"my-0 mx-0"},[_vm._v(" "+_vm._s(document.comment)+" ")])]),_c('td',[_c('p',{staticClass:"my-0 mx-0"},[_vm._v(" "+_vm._s(_vm.formatDate(document.created_at))+" ")])]),_c('td',[_c('p',{staticClass:"my-0 mx-0"},[_vm._v(" "+_vm._s(_vm.formatDate(document.updated_at))+" ")])])])}),0)]},proxy:true}],null,true)})]}}]),model:{value:(_vm.documentsTree),callback:function ($$v) {_vm.documentsTree=$$v},expression:"documentsTree"}}):_c('div',{staticClass:"text-center mt-16"},[_c('v-icon',{attrs:{"size":"50","color":"warning"}},[_vm._v("mdi-alert-circle")]),_c('h5',{staticClass:"mt-5"},[_vm._v("No hay Documentos para Mostrar")])],1)],1)],1),_c('modal-upload-document',{attrs:{"modalAction":_vm.dialogUploadDocument,"closeModalUploadDocument":_vm.closeModalUploadDocument,"isProject":_vm.isProject,"isPresale":_vm.isPresale,"currentDocument":_vm.currentDocument},on:{"documentCreatedOrUpdated":_vm.getDocumentsData}}),_c('filters',{attrs:{"modalAction":_vm.dialogFilterDocuments,"closeModalFilterDocuments":_vm.closeModalFilterDocuments},on:{"clearAllFilters":_vm.clearAllFilters,"searchFilters":_vm.searchFilters}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }