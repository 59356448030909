<template>
  <section>
    <v-dialog
      v-model="modalAction"
      max-width="600px"
      scrollable
      @keydown="executeCloseModalFilterDocuments"
      @click:outside="executeCloseModalFilterDocuments"
    >
      <v-card>
        <div class="p-4 pb-0">
          <h5>Filtros de Documentos</h5>
        </div>
        <hr />
        <v-card-text>
          <v-row class="px-4 mt-5">
            <v-col cols="12" md="12" sm="12" class="pt-0">
              <v-select
                v-model="filters.document_type_filter"
                :items="documentsTypes"
                item-text="name"
                item-value="name"
                label="Nombre de Documento"
                clearable
                class="mt-2"
              ></v-select>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="pt-0">
              <v-menu
                ref="menu"
                v-model="datepicker_menu_start"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_start_formatted"
                    prepend-icon="mdi-calendar"
                    readonly
                    label="Fecha (desde)"
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    @click:clear="clearDateFilters"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.date_start"
                  scrollable
                  locale="es"
                  :max="maxDateStart"
                  @input="datepicker_menu_start = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="pt-0">
              <v-menu
                ref="menu"
                v-model="datepicker_menu_end"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_end_formatted"
                    prepend-icon="mdi-calendar"
                    readonly
                    label="Fecha (hasta)"
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    @click:clear="clearDateFilters"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.date_end"
                  scrollable
                  locale="es"
                  clearable
                  :min="minDateEnd"
                  @input="datepicker_menu_end = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="emitClearAllFilters">
            <small> Limpiar Filtros </small>
          </v-btn>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalFilterDocuments"
          >
            <small> Cerrar </small>
          </v-btn>
          <v-btn color="primary" @click="searchFilters">
            <small> Buscar </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import moment from "moment";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalFilterDocuments: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      moment,
      datepicker_menu_start: false,
      datepicker_menu_end: false,
      filters: {
        document_type_filter: null,
        date_start: null,
        date_end: null,
      },
    };
  },
  methods: {
    executeCloseModalFilterDocuments($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.closeModalFilterDocuments($event);
      }
    },
    emitClearAllFilters() {
      this.filters.document_type_filter = null;
      this.filters.date_start = null;
      this.filters.date_end = null;
      this.$emit("clearAllFilters");
    },
    clearDateFilters() {
      this.filters.date_start = null;
      this.filters.date_end = null;
    },
    searchFilters() {
      this.$emit("searchFilters", {
        document_type_filter: this.filters.document_type_filter,
        date_start: this.filters.date_start,
        date_end: this.filters.date_end,
      });
      this.closeModalFilterDocuments(true);
    },
  },
  computed: {
    ...mapGetters({
      documentsTypes: "document/documentsTypes",
    }),
    date_start_formatted() {
      if (this.filters.date_start) {
        return moment(this.filters.date_start).format("DD/MM/YYYY");
      }
      return null;
    },
    date_end_formatted() {
      if (this.filters.date_end) {
        return moment(this.filters.date_end).format("DD/MM/YYYY");
      }
      return null;
    },
    minDateEnd() {
      if (this.filters.date_start) {
        return this.filters.date_start;
      }
      return null;
    },
    maxDateStart() {
      if (this.filters.date_end) {
        return this.filters.date_end;
      }
      return null;
    },
  },
};
</script>

<style></style>
